/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
.experience-commerce_layouts-carousel .carousel.slide-borders .pd-slide:not(:last-child) {
  position: relative; }
  .experience-commerce_layouts-carousel .carousel.slide-borders .pd-slide:not(:last-child):after {
    content: '';
    position: absolute;
    top: 0;
    left: 100%;
    height: 100%;
    width: calc((var(--spaceBtwnMobile) / 2) - 1px);
    border-right: 0.0625rem solid var(--color-grey3); }
    @media (min-width: 769px) {
      .experience-commerce_layouts-carousel .carousel.slide-borders .pd-slide:not(:last-child):after {
        width: calc((var(--spaceBtwnTablet) / 2) - 1px); } }
    @media (min-width: 992px) {
      .experience-commerce_layouts-carousel .carousel.slide-borders .pd-slide:not(:last-child):after {
        width: calc((var(--spaceBtwnDesktop) / 2) - 1px); } }
  .experience-commerce_layouts-carousel .carousel.slide-borders .pd-slide:not(:last-child).product:after {
    width: calc((var(--prodSpaceBtwnMobile) / 2) - 1px); }
    @media (min-width: 769px) {
      .experience-commerce_layouts-carousel .carousel.slide-borders .pd-slide:not(:last-child).product:after {
        width: calc((var(--prodSpaceBtwnTablet) / 2) - 1px); } }
    @media (min-width: 992px) {
      .experience-commerce_layouts-carousel .carousel.slide-borders .pd-slide:not(:last-child).product:after {
        width: calc((var(--prodSpaceBtwnDesktop) / 2) - 1px); } }
